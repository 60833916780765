import React,{useState, useEffect} from 'react';
import NavbarComp from '../../components/Navbar/navbar';
import blogImg from '../../assets/images/blogImage.png'
import FooterComp from '../../components/Footer/footer';
import styles from './blog.module.css'
import { Link } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment';


const BlogsPage = () => {

    const [blogs, setBlogs] = useState([])

    const [isSet, setVal] = useState('All');

    const [categories, setCategories] = useState([])

    useEffect(()=>{
     const fetchCategories = async () =>{
         const resp = await axios.get('https://reachadeal.herokuapp.com/api/categories')
         setCategories(resp.data.data)
     }
     fetchCategories()
    },[])

    useEffect(()=>{
        const fetchBlogs = async () =>{
            const resp = await axios.get('https://reachadeal.herokuapp.com/api/articles?populate=image,category')
          setBlogs(resp.data.data);
        };
        fetchBlogs()
    },[])

    const limitStr  = (string, limit) =>{
        let str = string;
  
        if (typeof str === 'string' && str.length > limit) {
          str = str.slice(0, limit) + '...';
        }
        
        return str;
      }

    const handleFilter = async (id,name) =>{
        setVal(name)   
        // make api call to get all articles under a category
        const resp = await axios.get(`https://reachadeal.herokuapp.com/api/categories/${id}?populate=articles,articles.image,articles.category`)
        setBlogs(resp.data.data.attributes.articles.data)
    }

    const handleAll = async (name) =>{
        setVal(name)
        const resp = await axios.get('https://reachadeal.herokuapp.com/api/articles?populate=image,category')
        setBlogs(resp.data.data);
    }

    return ( 
        <>
        <NavbarComp/>
        {/* hero section */}

        <div className={styles.blog_hero}>
             <div>
                <h5 className='absolute bottom-20 font-raleway text-white font-semibold text-2xl max-w-xs'>
                Top 5 Imports <br/> of Africa
                </h5>
            </div>
        </div>

        <div className='font-raleway container mx-auto px-6 md:py-20 py-10 md:px-12'>
            <h5 className='text-radblack  md:text-2xl text-lg font-bold'>Our Stories</h5>

            <ul className='mt-5 flex overflow-x-auto md:overflow-hidden'>
                 <li  onClick={() => handleAll('All')}>
                    <p 
                     className={
                        isSet === "All" ? 'text-tertiary4 pr-6 text-sm font-PtSans cursor-pointer font-bold'
                        :
                        'text-tertiary1 pr-6 text-sm font-PtSans cursor-pointer font-bold'
                    }
                    >
                    All</p>
                </li>
                {
                    categories.map((val)=>(
                        <>     
                        <li key={val.id}>
                            <p onClick={() => handleFilter(val.id, val.attributes.name)} 
                            className={
                                isSet === val.attributes.name ? 'text-tertiary4 pr-6 text-sm font-PtSans cursor-pointer font-bold'
                                :
                                'text-tertiary1 pr-6 text-sm font-PtSans cursor-pointer font-bold'
                            }
                            >{val.attributes.name}</p>
                        </li>
                        </>
                    ))
                }
            </ul>

            <div className='grid md:grid-cols-3 md:gap-6 gap-4 mt-10'>

                    {
                           blogs && blogs.length > 0 ? blogs.map((val)=>(    
                                <>             
                                <div className='mb-4' key={val.id}>
                                    <div className='relative'>
                                        <Link to={`/blog/${val.id}`}>
                                            <img src={blogImg} alt="stories" />
                                        </Link>
                                        <div className='absolute md:right-20 lg:right-8 right-4 top-5'>
                                            <span className='bg-tertiary3 py-3 px-4 rounded-lg uppercase text-white font-bold text-sm'>{
                                                val.attributes.category.data.attributes.name
                                            }</span>
                                        </div>
                                    </div>
                                    <p className='text-tertiary text-sm mt-4'>
                                    <Moment format="Do, MMM YYYY">{val.updatedAt}</Moment>
                                    </p>
                                    <h5 className='text-tertiary1 font-bold text-base mt-2'>{val.attributes.title}</h5>
                                    <p className='font-PtSans text-sm text-radblack max-w-md mt-1'>
                                            {limitStr(val.attributes.content,250)}
                                    </p>
                                </div>
                            </>
                                
                            ))
                            :
                            ""
                        }
            </div>
        </div>

        <FooterComp />
        </>
     );
}
 
export default BlogsPage;