import React,{useState, useEffect} from 'react';
import NavbarComp from '../../components/Navbar/navbar';
import blogImg from '../../assets/images/blogImage.png'
import FooterComp from '../../components/Footer/footer';
import styles from './blog.module.css'
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Moment from 'react-moment'


const BlogDetailsPage = () => {

    const {id} = useParams()

    const [blog, setBlog] = useState({})

    useEffect(()=>{
        const fetchBlog = async () =>{
           const resp = await axios.get(`https://reachadeal.herokuapp.com/api/articles/${id}?populate=image,category`)
           setBlog(resp.data.data);
        };
        fetchBlog()
    },[id])

    return ( 
        <>
        <NavbarComp />
        <div className={styles.blog_hero}>
             <div>
                <h5 className='absolute bottom-20 font-raleway text-white font-semibold text-2xl max-w-xs'>
                    {blog.hasOwnProperty('attributes') ? blog.attributes.title : ''}
                </h5>
            </div>
        </div>

        {/* blog info */}
        <div className='font-raleway container mx-auto px-6 py-12 md:py-20 md:px-12'>
            <div className='flex md:flex-row flex-col gap-6 md:gap-8'>
                <div className='flex flex-col'>
                    <div className='flex items-center'>
                        <p className='text-radblack text-sm font-bold pr-4'>
                            {blog.hasOwnProperty('attributes') ? <Moment format="Do, MMM YYYY">{blog.attributes.updatedAt}</Moment> : '08-08-2021'}
                            </p>
                        <div className='bg-tertiary5 w-5 h-px'></div>
                        <p className='text-radblack text-sm font-bold pl-4'>
                        {blog.hasOwnProperty('attributes') ? <Moment fromNow>{blog.attributes.updatedAt}</Moment> : '08-08-2021'}
                            </p>
                    </div>
                    <div className='md:mt-28 mt-5'>
                        <ul className='flex'>
                            <li>
                               <i className='mdi mdi-facebook pr-3 cursor-pointer'></i>
                            </li>
                            <li>
                               <i className='mdi mdi-twitter pr-3 cursor-pointer'></i>
                            </li>
                            <li>
                               <i className='mdi mdi-pinterest pr-3 cursor-pointer'></i>
                            </li>
                            <li>
                               <i className='mdi mdi-behance cursor-pointer'></i>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='max-w-5xl lg:max-w-4xl'>
                    <p className='text-tertiary1 font-PtSans text-md '>
                        {blog.hasOwnProperty('attributes') ? blog.attributes.content : ''}
                    </p>

                    
                </div>

            </div>

        </div>

        <div className="font-raleway container mx-auto px-6 pb-10 md:pb-20 md:px-12">
            <h5 className='text-radblack  md:text-2xl text-lg font-bold'>Related Posts</h5>
            <div className='grid md:grid-cols-3 mt-8'>
                <div className='mb-8 md:mb-0'>
                    <div className='relative'>
                        <img src={blogImg} alt="stories" />
                        <div className='absolute md:right-20 lg:right-8 right-4 top-5'>
                            <span className='bg-tertiary3 py-3 px-4 rounded-lg uppercase text-white font-bold text-sm'>Adventure</span>
                        </div>
                    </div>
                    <p className='text-tertiary text-sm mt-4'>08.08.2021</p>
                    <h5 className='text-tertiary1 font-bold text-base mt-2'>Top 5 Imports of Africa</h5>
                    <p className='font-PtSans text-sm text-radblack max-w-md mt-1'>
                    Africa’s growing population and demand for consumer and capital goods have necessitated an increase in the import rate.
                    </p>
                </div>
                <div className='mb-8 md:mb-0'>
                    <div className='relative'>
                        <img src={blogImg} alt="stories" />
                        <div className='absolute md:right-20 lg:right-8 right-4 top-5'>
                            <span className='bg-tertiary3 py-3 px-4 rounded-lg uppercase text-white font-bold text-sm'>Adventure</span>
                        </div>
                    </div>
                    <p className='text-tertiary text-sm mt-4'>08.08.2021</p>
                    <h5 className='text-tertiary1 font-bold text-base mt-2'>Top 5 Imports of Africa</h5>
                    <p className='font-PtSans text-sm text-radblack max-w-md mt-1'>
                    Africa’s growing population and demand for consumer and capital goods have necessitated an increase in the import rate.
                    </p>
                </div>
                <div className='mb-8 md:mb-0'>
                    <div className='relative'>
                        <img src={blogImg} alt="stories" />
                        <div className='absolute md:right-20 lg:right-8 right-4 top-5'>
                            <span className='bg-tertiary3 py-3 px-4 rounded-lg uppercase text-white font-bold text-sm'>Adventure</span>
                        </div>
                    </div>
                    <p className='text-tertiary text-sm mt-4'>08.08.2021</p>
                    <h5 className='text-tertiary1 font-bold text-base mt-2'>Top 5 Imports of Africa</h5>
                    <p className='font-PtSans text-sm text-radblack max-w-md mt-1'>
                    Africa’s growing population and demand for consumer and capital goods have necessitated an increase in the import rate.
                    </p>
                </div>
            </div>
            </div>

            <FooterComp />


        </>
     );
}
 
export default BlogDetailsPage;