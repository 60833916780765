import React from 'react';
import NavbarComp from '../../components/Navbar/navbar';
import img1 from '../../assets/images/image6.png'
import ContactComp from '../../components/ContactForm/contact';
import FooterComp from '../../components/Footer/footer';


const ServicesPage = () => {
    return ( 
        <>
            <NavbarComp/>
            <div className='font-raleway container mx-auto px-6 py-10 md:py-16 md:px-12'>
                <div className='border-b border-b-tertiary6'>
                    <h3 className='text-radblack font-semibold text-3xl mb-3'>Our Services</h3>
                </div>

                <div className='grid md:grid-cols-2 md:pt-16 pt-10 md:gap-6'>
                    <div>
                        <h4 className='text-black font-bold text-xl'>GENERAL CONTRACTS</h4>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        Reach A deal Africa serves as a general contractor with strong expertise in the field of Project Management who handles your construction, renovation and improvements projects. Our clients include Individuals, corporations and governments
                        </p>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        We have a full stacked team of architects, engineers, electricians, plumbers and the likes who are experienced and have been professionally trained to deliver excellent projects free of errors
                        </p>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        We believe in doing the best and upholding high moral standards and integrity in the execution of our projects. You can confidently expect us to:
                        </p>

                        <div className='flex mt-5 items-center gap-4'>
                            {/* <div className='w-3 h-3 rounded-full bg-radblack'></div> */}
                            <div className='font-PtSans text-black text-sm'>1.</div>
                            <div className='flex-1'>
                                <p className='font-PtSans text-black text-sm'>Execute your project with excellence and quality that rids you of complaints.</p>
                            </div>
                        </div>
                        <div className='flex mt-4 items-center gap-4'>
                            {/* <div className='w-3 h-3 rounded-full bg-radblack'></div> */}
                            <div  className='font-PtSans text-black text-sm'>2.</div>
                            <div className='flex-1'>
                                <p className='font-PtSans text-black text-sm'>
                                Make judicious use of your financial resources and time in delivering on your project, and
                                </p>
                            </div>
                        </div>
                        <div className='flex mt-4 items-center gap-4'>
                            {/* <div className='w-3 h-3 rounded-full bg-radblack'></div> */}
                            <div className='font-PtSans text-black text-sm'>3.</div>
                            <div className='flex-1'>
                                <p className='font-PtSans text-black text-sm'>
                                Ensure the use of standard equipment and materials that keeps your mind at peace.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='mt-10 md:mt-0'>
                        <div>
                            <img src={img1} alt="services" />
                        </div>
                    </div>

                </div>
            </div>
            <ContactComp />
         <FooterComp/>
        </>
     );
}
 
export default ServicesPage;