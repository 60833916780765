import React from 'react';
import NavbarComp from '../../components/Navbar/navbar';
import img1 from '../../assets/images/consultancy.png'
import ContactComp from '../../components/ContactForm/contact';
import FooterComp from '../../components/Footer/footer';


const ConsultancyPage = () => {
    return ( 
        <>
            <NavbarComp/>
            <div className='font-raleway container mx-auto px-6 py-10 md:py-16 md:px-12'>
                <div className='border-b border-b-tertiary6'>
                    <h3 className='text-radblack font-semibold text-3xl mb-3'>Our Services</h3>
                </div>

                <div className='grid md:grid-cols-2 md:pt-16 pt-10 md:gap-6'>
                    <div>
                        <h4 className='text-black font-bold text-xl'>Consultancy</h4>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        Reach A Deal Consultancy is one stop that provides solutions to your business problems. As a team of business experts and consultants, we are committed to the growth and development of your business for global competitiveness, especially for businesses in Nigeria and any African country.
                        </p>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        We serve as the vehicle that takes your business from its present position to where you desire it to be. With our astute research, strategic planning and execution skills, we offer excellent professional service that achieves the goals of our clients
                        </p>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        Through the stages of establishment, expansion and restructuring of your business, Reach A deal Africa offers services that include business formation, license support, business planning and development whether for Startups, SMEs or large conglomerates.
                        </p>

                    </div>
                    <div className='mt-10 md:mt-0'>
                        <div>
                            <img src={img1} alt="services" />
                        </div>
                    </div>

                </div>
            </div>
            <ContactComp />
         <FooterComp/>
        </>
     );
}
 
export default ConsultancyPage;