import React, {useState} from 'react';
import NavbarComp from '../../components/Navbar/navbar';
import styles from './home.module.css';
import img1 from '../../assets/images/image2.png'
import img2 from '../../assets/images/image3.png'
import img3 from '../../assets/images/image4.png'
import img4 from '../../assets/images/image5.png'
import img5 from '../../assets/images/business.png'
import img6 from '../../assets/images/trading.png'
import arrow_right from '../../assets/icons/arrow_right_black.svg'
import arrow_down from '../../assets/icons/arrow_down.svg'
import arrow_left_circle from '../../assets/icons/arrow_left_circle.png'
import arrow_right_circle from '../../assets/icons/arrow_right_circle.png'
import blogImg from '../../assets/images/blogImage.png'
import { Link } from 'react-router-dom';
import ContactComp from '../../components/ContactForm/contact';
import FooterComp from '../../components/Footer/footer';



const HomePage = () => {

    const [val, setVal] = useState(1);

    const toggleCard = (id) =>{
        setVal(id === val ? null : id)     
    }

    const FAQS = [
        {
          id: 1,
          title: "Our Reach",
          content: () => (
            <>
            Our business scope transcends our immediate environment and as such we have a global outlook in the services we provide and how we render the services. Our pool of resources and access makes us break the limitations in doing business internationally.
            </>
          ),
        },
        {
          id: 2,
          title: "Our Team",
          content: () => (
            <>
           We pride in the fact that we have a highly committed team of professionals and partners who share in the vision of the firm in delivering excellent business services. Our team relentlessly and effectively helps our clients get the best value offering they deserve.
            </>
          ),
        },
        {
          id: 3,
          title: "Our Products",
          content: () => (
            <>
             We have a wide range of imported products that ensure our customers get the ultimate satisfaction they desire and also varieties of exported products in categories of cash crops, seeds,nuts and spices etc., that make our clients get the best value offering they want.
            </>
          ),
        },
        {
            id: 4,
            title: "Our Values",
            content: () => (
              <>
              As a business entity, we are committed to maintaining a high moral and ethical standard that puts our customers' confidence in security and makes our business process free of toxicity. We uphold the values of Integrity, transparency and efficiency in doing business internally and externally.
              </>
            ),
          },
      ];

    return ( 
        <>
        <NavbarComp />  
        <div className={`${styles.home_hero_div}`}>
            <div className='absolute bottom-8 md:bottom-12 flex w-full md:items-end md:flex-row flex-col md:justify-between px-4 py-4 md:px-12'>
                <div>
                    <h5 className='font-raleway text-white font-semibold text-2xl md:text-3xl md:max-w-lg'>
                    We prioritize efficiency and expert service delivery to our wide variety of clients.</h5>
                </div>
                <div className='flex mt-5 md:mt-0'>
                    <div className='cursor-pointer md:h-6 md:w-6'>
                        <img src={arrow_left_circle} alt="arrow" />
                    </div>
                    <div className='md:ml-8 ml-5 md:h-6 md:w-6 cursor-pointer'>
                    <img src={arrow_right_circle} alt="arrow" />
                    </div>
                </div>
            </div>   
        </div>
        <div id="about" className='font-raleway container mx-auto px-6 pt-10 md:px-12'>
            <div>
                <h5 className='text-radblack md:text-2xl text-lg font-bold'>What we do</h5>
                <p className='text-black max-w-xl font-PtSans leading-5 mt-3 font-normal'>
                At Reach A Deal Africa Limited, we provide a trading service like no other. As an African-based independent trading company, we prioritize efficiency and expert service delivery to our wide variety of clients. Our goal is to provide our clientele with the best competitive prices for their goods within their stated deadlines and estimated budget.
                </p>
            </div>

            <div id="services" className="grid md:grid-cols-2 mt-8 md:mt-8 md:gap-6 2xl:gap-2">
                <div className="grid md:grid-cols-2 items-end gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">General Contracts</h6>
                        <p className="font-PtSans leading-5 mt-2 md:max-w-xs font-normal">
                        Reach A deal Africa serves as a general contractor with strong expertise in the field of Project Management who handles your construction, renovation and improvements projects. Our clients include Individuals, corporations and governments.
                        </p>
                        <Link to="/services/contracts" className='flex items-center mt-3 cursor-pointer'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div className='text-center'>
                        <img src={img1} alt="what we do" />
                    </div>
                 </div>
                 <div className="grid md:grid-cols-2 items-end mt-10 md:mt-0 gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">Import and Export</h6>
                        <p className="font-PtSans leading-5 mt-2 max-w-xs font-normal">
                        Importing and Exporting has been made easy with the fast, safe, trusted, and affordable import and export services of Reach A Deal Africa Limited.
                        </p>
                        <Link to="/services/import-export" className='flex items-center mt-3 cursor-pointer'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div>
                        <img src={img2} alt="what we do" />
                    </div>
                 </div>
            </div>

            <div className="grid md:grid-cols-2 mt-10 md:mt-8 md:gap-6 2xl:gap-2">
                <div className="grid md:grid-cols-2 items-end gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">Consultancy</h6>
                        <p className="font-PtSans leading-5 mt-2 max-w-xs font-normal">
                       Reach A deal Africa helps her clients in buying and selling of commodities such as but not limited to oil, graze, metals, sugar, and coffee. We also trade complex financial products with a well-diversified range of assets of equities and bonds
                        </p>
                        <Link to="/services/consultancy" className='flex items-center mt-3 cursor-pointer'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div>
                        <img src={img4} alt="what we do" />
                    </div>
                 </div>
                 <div className="grid md:grid-cols-2 mt-10 md:mt-0 items-end gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">Commodity brokering</h6>
                        <p className="font-PtSans leading-5 mt-2 max-w-xs font-normal">
                        Reach A deal Africa helps her clients in buying and selling of commodities such as but not limited to oil, graze, metals, sugar, and coffee. We also trade complex financial products with a well-diversified range of assets of equities and bonds
                        </p>
                        <Link to="/services/commodity" className='flex items-center mt-3 cursor-pointer no-underline'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div>
                        <img src={img3} alt="what we do" />
                    </div>
                 </div>
            </div>
            <div className="grid md:grid-cols-2 mt-10 md:mt-8 md:gap-6 2xl:gap-2">
                <div className="grid md:grid-cols-2 items-end gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">BUSINESS ACQUISITION AND MERGERS </h6>
                        <p className="font-PtSans leading-5 mt-2 max-w-xs font-normal">
                        Reach A deal Africa offers professional service in facilitating the mergers and acquisition of businesses across industries ranging from Agriculture, Logistics, Fast Moving Consumer Goods and Electronics and so on.
                        </p>
                        <Link to="/services/business-acquisition" className='flex items-center mt-3 cursor-pointer'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div>
                        <img src={img5} alt="what we do" />
                    </div>
                 </div>
                 <div className="grid md:grid-cols-2 mt-10 md:mt-0 items-end gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">GENERAL TRADING</h6>
                        <p className="font-PtSans leading-5 mt-2 max-w-xs font-normal">
                        Reach A Deal Africa Limited exists as a large enterprise that engages in a pool of diversified goods and services that are used by individuals, households, corporates & business organisations and the government.
                        </p>
                        <Link to="/services/trading" className='flex items-center mt-3 cursor-pointer no-underline'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div>
                        <img src={img6} alt="what we do" />
                    </div>
                 </div>
            </div>


            <div className="md:grid md:grid-cols-3 md:gap-6 md:py-20 py-10">
                <div className={styles.home_card_div}>
                    <div className={`${styles.home_card_img}`} >
                    <div className="absolute bottom-8 px-6">
                            <h5 className="text-white font-bold text-lg font-raleway max-w-sm">PERSONALIZED LOGISTIC & SHIPPING</h5>
                        </div>
                        <div className={styles.card_overlay}>
                            <h5 className="font-raleway absolute bottom-8 text-white md:text-lg font-bold px-6"
                            >
                                We not only aim at moving and transporting your goods from one destination to another but also aspire to work within the specifications and instructions provided by you.
                            </h5>
                        </div>
                    </div>   
                </div>
                <div className={styles.home_card_div}>
                    <div className={`${styles.home_card_img2}`} >
                        <div className="absolute bottom-8 px-6">
                            <h5 className="text-white font-bold text-lg font-raleway max-w-sm">ROAD, SEAS & AIRCARGO</h5>
                        </div>
                        
                        <div className={styles.card_overlay}>
                            <h5 className="font-raleway absolute bottom-8 text-white md:text-lg font-bold px-6"
                            >
                                We not only aim at moving and transporting your goods from one destination to another but also aspire to work within the specifications and instructions provided by you.
                            </h5>
                        </div>
                    </div>   
                </div>
                <div className={styles.home_card_div}>
                    <div className={`${styles.home_card_img3}`} >
                    <div className="absolute bottom-8 px-6">
                            <h5 className="text-white font-bold text-lg font-raleway max-w-sm">TIMELY & ECONOMICAL SHIPPING</h5>
                        </div>
                        <div className={styles.card_overlay}>
                            <h5 className="font-raleway absolute bottom-8 text-white md:text-lg font-bold px-6"
                            >
                                We not only aim at moving and transporting your goods from one destination to another but also aspire to work within the specifications and instructions provided by you.
                            </h5>
                        </div>
                    </div>   
                </div>
            </div>

            {/* faq */}
            <div className='py-20 md:max-w-5xl mx-auto'>
                <div className='bg-tertiary7 p-8 md:p-10 rounded-2xl font-raleway'>
                    <div className='text-center'>
                        <h4 className='font-radblack font-bold text-xl  md:text-2xl'>Why we are the best</h4>
                        <p className='font-radblack md:max-w-3xl md:mx-auto mt-3 text-sm md:text-base'>
                        We have a lot of factors that give us a competitive edge in the rendering of our services and serve as reasons for us being the first choice in the industry.
                        </p>
                    </div>

                    {/* faq contents */}
                    {FAQS.map(({ id, title, content }) => (
                        <div className='mt-6' key={id}>
                          <div className='flex justify-between border-b border-b-radblack_opa'>
                              <div className='mb-3'>
                                  <h6 className='text-radblack font-bold text-base'>{title}</h6>
                              </div>
                              <div className='cursor-pointer mb-3' onClick={() => toggleCard(id)}>
                                  <img src={arrow_down} alt="arrow down" />
                              </div>
                          </div>
                          <p 
                           className={
                            val === id
                              ? "text-sm font-PtSans mt-4 block"
                              : "text-sm font-PtSans mt-4 hidden"
                          }
                            >
                            {content()}
                          </p> 
                      </div>
                    ))}
                </div>

            </div>


        </div>

        <ContactComp title="Send a message" />

        <div className="font-raleway container mx-auto px-6 pb-20 md:px-12">

            <h5 className='text-radblack  md:text-2xl text-lg font-bold'>Our Stories</h5>
            <div className='grid md:grid-cols-3 mt-8 md:gap-6'>
                <div className='mb-8 md:mb-0'>
                    <div className='relative'>
                        <Link to="/blog/1">
                            <img src={blogImg} alt="stories" />
                        </Link>
                        <div className='absolute md:right-20 right-4 top-5'>
                            <span className='bg-tertiary3 py-3 px-4 rounded-lg uppercase text-white font-bold text-sm'>Adventure</span>
                        </div>
                    </div>
                    <p className='text-tertiary text-sm mt-4'>08.08.2021</p>
                    <h5 className='text-tertiary1 font-bold text-base mt-2'>Top 5 Imports of Africa</h5>
                    <p className='font-PtSans text-sm text-radblack max-w-md mt-1'>
                    Africa’s growing population and demand for consumer and capital goods have necessitated an increase in the import rate.
                    </p>
                </div>
                <div className='mb-8 md:mb-0'>
                     <div className='relative'>
                     <Link to="/blog/2">
                            <img src={blogImg} alt="stories" />
                        </Link>
                        <div className='absolute md:right-20 right-4 top-5'>
                            <span className='bg-tertiary3 py-3 px-4 rounded-lg uppercase text-white font-bold text-sm'>Adventure</span>
                        </div>
                    </div>
                    <p className='text-tertiary text-sm mt-4'>08.08.2021</p>
                    <h5 className='text-tertiary1 font-bold text-base mt-2'>Africa’s Top Exports and Exporting Countries.</h5>
                    <p className='font-PtSans text-sm text-radblack max-w-md mt-1'>
                    Even though imports to Africa exceed the export rate by a large margin, Africa is known for valuable exports.
                    </p>
                </div>
                <div >
                    <div className='relative'>
                        <img src={blogImg} alt="stories" />
                        <div className='absolute md:right-20 right-4 top-5'>
                            <span className='bg-tertiary3 py-3 px-4 rounded-lg uppercase text-white font-bold text-sm'>Adventure</span>
                        </div>
                    </div>
                    <p className='text-tertiary text-sm mt-4'>08.08.2021</p>
                    <h5 className='text-tertiary1 font-bold text-base mt-2'>Top 5 Imports of Africa</h5>
                    <p className='font-PtSans text-sm text-radblack max-w-md mt-1'>
                    Africa’s growing population and demand for consumer and capital goods have necessitated an increase in the import rate.
                    </p>
                </div>
            </div>


        </div>

        <FooterComp />
        </>
     );
}
 
export default HomePage;