import React from 'react';
import NavbarComp from '../../components/Navbar/navbar';
import img1 from '../../assets/images/commodity.png'
import ContactComp from '../../components/ContactForm/contact';
import FooterComp from '../../components/Footer/footer';


const CommodityPage = () => {
    return ( 
        <>
            <NavbarComp/>
            <div className='font-raleway container mx-auto px-6 py-10 md:py-16 md:px-12'>
                <div className='border-b border-b-tertiary6'>
                    <h3 className='text-radblack font-semibold text-3xl mb-3'>Our Services</h3>
                </div>

                <div className='grid md:grid-cols-2 md:pt-16 pt-10 md:gap-6'>
                    <div>
                        <h4 className='text-black font-bold text-xl'>Commodity Brokering</h4>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        Reach A deal Africa helps her clients in buying and selling of commodities such as but not limited to oil, graze, metals, sugar, and coffee. We also trade complex financial products with a well-diversified range of assets of equities and bonds
                        </p>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        Reach A Deal Africa Limited employs her expertise in delivering excellent commercial offerings across the investment and supply chain management space, with provision of services such as;
                        </p>
                        <ul className='list-disc pl-4'>
                            <li>
                                <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                                Investigation and monitoring of market performance
                                </p>
                            </li>
                            <li>
                                <p className='mt-1 font-PtSans text-black text-sm max-w-xl'>
                                Provision of smart and research-based investment recommendations and advice
                                </p>
                            </li>
                            <li>
                                <p className='mt-1 font-PtSans text-black text-sm max-w-xl'>
                                Trading and formulation of hedge strategies
                                </p>
                            </li>
                            <li>
                                <p className='mt-1 font-PtSans text-black text-sm max-w-xl'>
                                Meeting with local and foreign clients.
                                </p>
                            </li>
                            <li>
                                <p className='mt-1 font-PtSans text-black text-sm max-w-xl'>
                                Business Negotiations
                                </p>
                            </li>
                            <li>
                                <p className='mt-1 font-PtSans text-black text-sm max-w-xl'>
                                Partnerships with transport, shipping and insurance companies.
                                </p>
                            </li>
                        </ul>
                       

                    </div>
                    <div className='mt-10 md:mt-0'>
                        <div>
                            <img src={img1} alt="services" />
                        </div>
                    </div>

                </div>
            </div>
            <ContactComp />
         <FooterComp/>
        </>
     );
}
 
export default CommodityPage;