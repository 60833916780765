import React, {useState} from 'react';


const ContactComp = ({title}) => {

    const [name,setName] = useState("")
    const [email,setEmail] = useState("")
    const [message,setMessage] = useState("")

    const handleSubmit = (e) =>{
        e.preventDefault();
        console.log(name)
        console.log(email)
        console.log(message)
    }
    return ( 
        <>
        <div id="contact" className='font-raleway container mx-auto px-6 pb-16 md:px-12'>
            <div className='grid md:grid-cols-2'>
                <div className='bg-contactImg bg-no-repeat bg-cover rounded-tl-2xl rounded-bl-2xl'>

                </div>
                <div className='bg-secondary rounded-xl md:rounded-none md:rounded-r-xl px-6 md:px-12 pt-10 md:pt-12 pb-10 md:pb-16'>
                    <h5 className='text-white md:text-2xl font-bold'>{title}</h5>
                    <form onSubmit={(e) =>handleSubmit(e)} className="pt-6 max-w-xl">
                        <div className="mb-6">
                         <input 
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            required
                         className="appearance-none border border-1 font-PtSans placeholder:text-white rounded-2xl w-full text-base bg-transparent py-3 px-4 text-white" id="name" type="text" placeholder="Your Name"/>
                        </div>
                        <div className="mb-6">
                         <input
                          value={email}
                          required
                          onChange={(e) => setEmail(e.target.value)}
                          className="appearance-none border border-1 font-PtSans placeholder:text-white rounded-2xl w-full text-base bg-transparent py-3 px-4 text-white" id="email" type="email" placeholder="Email"/>
                        </div>
                        <div className="mb-4">
                            <textarea 
                            required
                             value={message}
                             onChange={(e) => setMessage(e.target.value)}
                            rows="6"
                            className="appearance-none resize-none border border-1 font-PtSans placeholder:text-white rounded-2xl w-full text-base bg-transparent py-3 px-4 text-white" id="message" type="text" placeholder="Your Message"
                            />
                        </div>
                        <button type='submit' className="bg-2 w-full hover:bg-primary font-PtSans text-white py-4 px-4 rounded-2xl font-normal focus:outline-none focus:shadow-outline">
                           Contact Us
                        </button>
                    </form>
                </div>  
            </div>

        </div>
        </>
     );
}
 
export default ContactComp;