import React from 'react';
import NavbarComp from '../../components/Navbar/navbar';
import img1 from '../../assets/images/tradingBig.png'
import ContactComp from '../../components/ContactForm/contact';
import FooterComp from '../../components/Footer/footer';


const TradingPage = () => {
    return ( 
        <>
            <NavbarComp/>
            <div className='font-raleway container mx-auto px-6 py-10 md:py-16 md:px-12'>
                <div className='border-b border-b-tertiary6'>
                    <h3 className='text-radblack font-semibold text-3xl mb-3'>Our Services</h3>
                </div>

                <div className='grid md:grid-cols-2 md:pt-16 pt-10 md:gap-6'>
                    <div>
                        <h4 className='text-black font-bold text-xl'>GENERAL TRADING</h4>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        Reach A Deal Africa Limited exists as a large enterprise that engages in a pool of diversified goods and services that are used by individuals, households, corporates & business organisations and the government.
                        </p>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                      We maintain a strong  supply chain management that ensures excellent delivery of products from our stores to different end customers. As one of Africa's largest importers and distributors of consumer products, Reach A deal Africa trades in product categories such as Fast Moving Consumer Goods(FMCGs), Agricultural products, Automobiles, Electronics, Construction equipment , Household equipment and appliances etc.
                        </p>
                      

                    </div>
                    <div className='mt-10 md:mt-0'>
                        <div>
                            <img src={img1} alt="services" />
                        </div>
                    </div>

                </div>
            </div>
            <ContactComp />
         <FooterComp/>
        </>
     );
}
 
export default TradingPage;