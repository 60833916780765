import React from 'react';
import NavbarComp from '../../components/Navbar/navbar';
import img1 from '../../assets/images/import.png'
import ContactComp from '../../components/ContactForm/contact';
import FooterComp from '../../components/Footer/footer';


const ImportExportPage = () => {
    return ( 
        <>
            <NavbarComp/>
            <div className='font-raleway container mx-auto px-6 py-10 md:py-16 md:px-12'>
                <div className='border-b border-b-tertiary6'>
                    <h3 className='text-radblack font-semibold text-3xl mb-3'>Our Services</h3>
                </div>

                <div className='grid md:grid-cols-2 md:pt-16 pt-10 md:gap-6'>
                    <div>
                        <h4 className='text-black font-bold text-xl'>Import & Export</h4>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        Importing and Exporting has been made easy with the fast, safe, trusted, and affordable import and export services of Reach A Deal Africa Limited. 
                        </p>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        Reach A deal Africa helps her clients import goods like cars, automobile spare parts, industrial equipment, electronics, household appliances, and various consumer goods, into different parts of Nigeria and other African countries
                        </p>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        As a company interested in the proper functioning of International trade, we also provide excellent export services for our customers especially those in the Agricultural business who desire to have their raw or processed products of produce such as cash crops, nuts, seeds and spices  get into various regions of the world.
                        </p>

                    </div>
                    <div className='mt-10 md:mt-0'>
                        <div>
                            <img src={img1} alt="services" />
                        </div>
                    </div>

                </div>
            </div>
            <ContactComp />
         <FooterComp/>
        </>
     );
}
 
export default ImportExportPage;