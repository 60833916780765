import React, {useState, useEffect} from 'react';
import rad_logo from '../../assets/icons/rad_logo.svg'
import {HashLink as Link,} from 'react-router-hash-link'

const NorthNavbarComp = () => {

    const [isOpen, setIsOpen] = useState(false)

    const toggleOpen = () =>{
        setIsOpen(!isOpen)
    }

const [scroll, setScroll] = useState(false);
 useEffect(() => {
   window.addEventListener("scroll", () => {
     setScroll(window.scrollY > 50);
   });
 }, []);


    return ( 
        <>
        <header
          className={
            scroll
            ?
            "bg-white scrolled"
            :
            "bg-white"
        }
        >
            <div 
              className={
                scroll ?
                "container mx-auto px-6 md:py-2 py-2 md:px-12 font-raleway"
                :
                "container mx-auto px-6 md:py-6 py-3 md:px-12 font-raleway"
            }>
                <nav 
                 className={
                    scroll ?
                    "flex flex-wrap items-center md:items-center justify-between"
                    :
                    "flex flex-wrap items-start md:items-start justify-between"
                }>

                {
                    scroll ?
                    (
                    <Link to="/">
                        <img src={rad_logo} className='md:h-16 h-10' alt="logo" />
                    </Link> 
                    )
                    :
                    <h4 className='font-radblack font-raleway font-extrabold text-lg md:text-5xl max-w-xs md:max-w-lg'>
                     REACH A DEAL <br/> NORTH AMERICA
                    </h4> 
                }
                   

                    {/* hambrger */}
                    <div className="md:hidden" onClick={toggleOpen}>
                        <button type="button" className="rounded-md p-1 inline-flex items-center justify-center text-secondary-200 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary" aria-expanded="false">
                        <span className="sr-only">Open menu</span>
                        <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                        </button>
                    </div>

                    <ul className='hidden md:flex items-center'>
                        <li>
                            <Link to="/north-america" className='block no-underline text-sm px-4 text-radblack hover:text-primary font-medium '>Home</Link>
                        </li>
                        <li>
                            <Link to="/north-america#about" className='block no-underline text-sm px-4 text-radblack hover:text-primary font-medium'>About</Link>
                        </li>
                        <li>
                            <Link to="/north-america#services" className='block no-underline text-sm px-4 text-radblack hover:text-primary font-medium'>Services</Link>
                        </li>
                        <li>
                            <Link to="/blog" className='block no-underline text-sm px-4 text-radblack hover:text-primary font-medium'>Blog</Link>
                        </li>                 
                        <li>
                            <Link to="/north-america#contact"  className='block no-underline text-sm px-4 text-radblack hover:text-primary font-medium'>Contact</Link>
                        </li>
                    </ul>

             {/* mobile menu */}
                <div 
                className={
                    isOpen ? 
                    "absolute top-0 inset-x-0 z-50 p-2 transition transform origin-top-right lg:hidden"
                    :
                    "absolute top-0 inset-x-0 z-50 p-2 transition transform origin-top-right lg:hidden hidden"
                }
                    >
                    <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">

                    <div className="px-5 pt-4 flex items-center justify-between">
                        <div>
                             <img src={rad_logo} className='h-12 w-auto' alt="logo" />
                        </div>

                    {/* close icon */}
                        <div className="-mr-2 md:mr-4" >
                        <button type="button" onClick={toggleOpen} className="rounded-md p-1 inline-flex items-center justify-center text-secondary-200 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-primary">
                            <span className="sr-only">Close main menu</span>
                        
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        </div>

                    </div>

                    <div className="px-2 pt-4 pb-4 space-y-1">
                            <Link to="/north-america" className="block px-3 py-2 rounded-md font-medium text-sm text-radblack hover:text-primary hover:bg-gray-50">Home</Link>
            
                            <Link to="/north-america#about" className="block px-3 py-2 rounded-md text-sm text-radblack font-medium hover:text-primary hover:bg-gray-50">About</Link>
                            <Link to="/services" className="block px-3 py-2 rounded-md text-sm text-radblack font-medium hover:text-primary hover:bg-gray-50">Services</Link>
                            <Link to="/blog" className="block px-3 py-2 rounded-md text-sm text-radblack font-medium hover:text-primary hover:bg-gray-50">Blog</Link>
                            <Link to="/north-america#contact" className="block px-3 py-2 rounded-md text-sm text-radblack font-medium hover:text-primary hover:bg-gray-50">Contact</Link>
                    </div> 
                    </div>
                </div>
                </nav>
            </div>
            </header>  
        </>
     );
}
 
export default NorthNavbarComp;