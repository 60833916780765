import React, {useState} from 'react';
import NorthNavbarComp from '../../components/Navbar/NorthNavbar';
import arrow_left_circle from '../../assets/icons/arrow_left_circle.png'
import arrow_right_circle from '../../assets/icons/arrow_right_circle.png'
import arrow_right_colored from '../../assets/icons/arrow_colored_rightcircle.png'
import arrow_left_colored from '../../assets/icons/arrow_colored_leftcircle.png'
import styles from './home.module.css';
import arrow_right from '../../assets/icons/arrow_right_black.svg'
import img1 from '../../assets/images/airFreight.png'
import img2 from '../../assets/images/image3.png'
import land from '../../assets/images/land.png'
import exp from '../../assets/images/export.png'
import comm from '../../assets/images/services.png'
import arrow_active from '../../assets/icons/arrow_colored_right.svg'
import arrow from '../../assets/icons/arrrow_colored.svg'
import car from '../../assets/images/car_shipping.png'
import { Link } from 'react-router-dom';
import ContactComp from '../../components/ContactForm/contact';
import FooterComp from '../../components/Footer/footer';

const NorthAmericaHomePage = () => {

    const [val, setVal] = useState(1);

    const toggleCard = (id) =>{
        setVal(id)     
    }

    const aboutContent = [
        {
            id: 1,
            name: "Company Overview"
        },
        {
            id: 2,
            name: "Aspiration & vision"
        },
        {
            id: 3,
            name: "Exceptional services"
        },
        {
            id: 4,
            name: "International clientele"
        }
    ]

    return (
        <>
        <NorthNavbarComp />
        <div className={styles.home_hero_div}>
            <div className='absolute bottom-8 md:bottom-12 flex w-full md:items-end md:flex-row flex-col md:justify-between px-4 py-4 md:px-12'>
                <div>
                    <h5 className='font-raleway text-white font-semibold text-2xl md:text-3xl md:max-w-lg'>
                    We prioritize efficiency and expert service delivery to our wide variety of clients.</h5>
                </div>
                <div className='flex mt-5 md:mt-0'>
                    <div className='cursor-pointer md:h-5 md:w-5'>
                        <img src={arrow_left_circle} alt="arrow" />
                    </div>
                    <div className='md:ml-8 ml-5 md:h-5 md:w-5 cursor-pointer'>
                    <img src={arrow_right_circle} alt="arrow" />
                    </div>
                </div>
            </div>   
        </div>

        <div id="about" className='font-raleway container mx-auto px-6 py-10 md:py-20 md:px-12'>
            <div className='flex justify-between items-start'>
                <div>
                    <h5 className='text-radblack md:text-2xl text-lg font-bold'>What we do</h5>
                    <p className='text-black max-w-md font-PtSans leading-5 mt-3 font-normal'>
                    At Reachdeal America, we are committed to powering your businesses.
                    </p>
                </div>
                <div className='flex'>
                    <div className='cursor-pointer md:h-6 md:w-6'>
                        <img src={arrow_left_colored} alt="arrow" />
                    </div>
                    <div className='md:ml-8 ml-5 md:h-6 md:w-6 cursor-pointer'>
                    <img src={arrow_right_colored} alt="arrow" />
                    </div>
                </div>
               
            </div>

            <div id="services" className="grid md:grid-cols-2 mt-8 md:mt-10 md:gap-6 2xl:gap-2">
                <div className="grid md:grid-cols-2 items-end gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">AIR FREIGHT</h6>
                        <p className="font-PtSans leading-5 mt-2 md:max-w-xs font-normal">
                        Our air freight capabiliy at Reach a deal North America allows us to deliver cargo to any city in the world. 
                        </p>
                        <Link to="/services/contracts" className='flex items-center mt-3 cursor-pointer'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div className='text-center'>
                        <img src={img1} alt="what we do" />
                    </div>
                 </div>
                 <div className="grid md:grid-cols-2 items-end mt-10 md:mt-0 gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">SEA SHIPPING</h6>
                        <p className="font-PtSans leading-5 mt-2 max-w-xs font-normal">
                        We are a shipping firm with current procedures and technologies to assure the safety of your cargos to any destination in the world, as well as to meet your container services demands, whether it's a less than container load (LCL) or a full container load (FCL) (FCL).
                        </p>
                        <Link to="/services/import-export" className='flex items-center mt-3 cursor-pointer'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div>
                        <img src={img2} alt="what we do" />
                    </div>
                 </div>
            </div>
            <div className="grid md:grid-cols-2 mt-8 md:mt-10 md:gap-6 2xl:gap-2">
                <div className="grid md:grid-cols-2 items-end gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">LAND TRANSPORTATION</h6>
                        <p className="font-PtSans leading-5 mt-2 md:max-w-xs font-normal">
                        Our ground transportation of cargo is less time consuming, safer, and more customizable to our clients' objectives. Whether it's a small or large shipment, a local or long-distance delivery, our trained transporters will make sure to meet all of your needs according to the criteria you set
                        </p>
                        <Link to="/services/contracts" className='flex items-center mt-3 cursor-pointer'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div className='text-center'>
                        <img src={land} alt="what we do" />
                    </div>
                 </div>
                 <div className="grid md:grid-cols-2 items-end mt-10 md:mt-0 gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">IMPORT & EXPORT</h6>
                        <p className="font-PtSans leading-5 mt-2 max-w-xs font-normal">
                        Our global reach and presence in locations such as the United States, Canada, Asia, Mexico, China, Japan, South Korea, Africa, and the Middle East make our import and export services dependable and timely. 
                        </p>
                        <Link to="/services/import-export" className='flex items-center mt-3 cursor-pointer'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div>
                        <img src={exp} alt="what we do" />
                    </div>
                 </div>
            </div>
            <div className="grid md:grid-cols-2 mt-8 md:mt-10 md:gap-6 2xl:gap-2">
                <div className="grid md:grid-cols-2 items-end gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">CAR SHIPPING</h6>
                        <p className="font-PtSans leading-5 mt-2 md:max-w-xs font-normal">
                        Do you work for a vehicle dealership, a large organization, or an individual? Then you've come to the right place. At Reach a deal North Africa, we take care of all of your car shipping needs in a safe and secure manner. We provide the greatest, safest, and most secure vehicle moving or transfer services in the world at competitive prices all over the world, while keeping in mind the amount of time our clients have given.
                        </p>
                        <Link to="/services/contracts" className='flex items-center mt-3 cursor-pointer'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div className='text-center'>
                        <img src={car} alt="what we do" />
                    </div>
                 </div>
                 <div className="grid md:grid-cols-2 items-end mt-10 md:mt-0 gap-6 md:gap-2">
                    <div>
                        <h6 className="font-bold text-black md:text-xl">COMMODITY BROKERING 
                        & INVESTMENT</h6>
                        <p className="font-PtSans leading-5 mt-2 max-w-xs font-normal">
                        We provide real-time market pricing information and only work with reputable suppliers and top-tier quality assurance firms. We have extensive knowledge of import/export legislation, trade procedures, and cross-cultural negotiations.
                        </p>
                        <Link to="/services/commodity" className='flex items-center mt-3 cursor-pointer'>
                            <p className='font-raleway text-black font-bold text-sm'>Read More</p>
                            <img className='ml-3 h-5' src={arrow_right} alt='arrow' />
                        </Link>
                    </div>
                    <div>
                        <img src={comm} alt="what we do" />
                    </div>
                 </div>
            </div>
        </div>

        <div className='font-raleway container mx-auto px-6 pb-10 md:pb-20 md:px-12'>
            <div className='grid md:grid-cols-2 gap-4'>
                <div className='bg-tertiary7 rounded-2xl p-8'>
                    <div>
                        <h5 className='text-radblack font-bold text-2xl'>Why us?</h5>
                    </div>

                    {aboutContent.map(({ id, name }) => (      
                     <div key={id} 
                        className={
                            val === id ?
                            "flex justify-between mt-5 items-center border-b border-b-primary cursor-pointer mb-2"
                            :
                            "flex justify-between mt-4 items-center cursor-pointer mb-2"
                        }
                        onClick={() => toggleCard(id)}
                        >
                            <div className='mb-2'>
                                <h6 
                                className={
                                    val === id ?
                                    "text-primary font-medium text-lg"
                                    :
                                    "text-radblack font-medium text-lg"
                                }
                             >{name}</h6>
                            </div>
                            <div className='mb-2'>
                                <img 
                                src={
                                    val === id ?
                                    arrow_active
                                    :
                                    arrow
                                }
                                alt="arrow" />
                            </div>
                        </div>
                    ))}
                </div>
                <div className='pl-3 md:pl-0'>

                    { val === 1 &&
                         <>
                        <h5 className='text-radblack font-bold text-lg'>Company Overview</h5>
                    <p className='mt-4 font-PtSans text-black text-sm'>
                        At Reach A Deal North America, we provide a trading service like no other. As an African-based independent trading company, we prioritize efficiency and expert service delivery to our wide variety of clients. Our goal is to provide our clientele with the best competitive prices for their goods within their stated deadlines and estimated budget.
                    </p>
                    <p className='mt-3 font-PtSans text-black text-sm'>
                         Our wide range of services include: 
                    </p>
                    <ul className='list-disc pl-6'>
                            <li>
                                <p className='mt-3 font-PtSans text-black text-sml'>
                                Import and Export,
                                </p>
                            </li>
                            <li>
                                <p className='mt-1 font-PtSans text-black text-sm'>
                                General Trading,
                                </p>
                            </li>
                            <li>
                                <p className='mt-1 font-PtSans text-black text-sm'>
                                General contracts,
                                </p>
                            </li>
                            <li>
                                <p className='mt-1 font-PtSans text-black text-sm'>
                                Commodity Brokering,
                                </p>
                            </li>
                            <li>
                                <p className='mt-1 font-PtSans text-black text-sm'>
                                Consultancy, and 
                                </p>
                            </li>
                            <li>
                                <p className='mt-1 font-PtSans text-black text-sm'>
                                Business acquisition and mergers 
                                </p>
                            </li>
                        </ul>

                        <p className='mt-3 font-PtSans text-black text-sm'>
                        Our Prospective clients can be rest assured that each service provided is delivered by well able professionals. Our experience cuts across all our services with a database of clients that routinely trust us to deliver their products in excellent condition.
                         </p>
                        </>
                    }

                    { val === 2 &&
                         <>
                        <h5 className='text-radblack font-bold text-lg'>Aspiration and Vision</h5>
                    <p className='mt-4 font-PtSans text-black text-sm'>
                    Our business scope transcends our immediate environment and as such we have a global outlook in the services we provide and how we render the services. Our pool of resources and access makes us break the limitations in doing business internationally.
                    </p>
                     </>
                    }

                    { val === 3 &&
                         <>
                            <h5 className='text-radblack font-bold text-lg'>Exceptional service</h5>
                        <p className='mt-4 font-PtSans text-black text-sm'>
                        As a business entity, we are committed to maintaining a high moral and ethical standard that puts our customers' confidence in security and makes our business process free of toxicity. We uphold the values of Integrity, transparency and efficiency in doing business internally and externally.
                        </p>
                     </>
                    }

                    { val === 4 &&
                         <>
                            <h5 className='text-radblack font-bold text-lg'>International Clientele</h5>
                        <p className='mt-4 font-PtSans text-black text-sm'>
                        Reach A Deal Consultancy is one stop that provides solutions to your business problems. As a team of business experts and consultants, we are committed to the growth and development of your business for global competitiveness, especially for businesses in Nigeria and any African country.
                        </p>
                     </>
                    }
                    
                </div>
            </div>

        </div>

        <ContactComp title="Request a quote" />
        <FooterComp />
        </>
      );
}
 
export default NorthAmericaHomePage;