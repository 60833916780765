import React from 'react';
import rad_logo from '../../assets/icons/rad_logo.svg'
import arrow_right from '../../assets/icons/arrow_right.svg'
import './landing.css'
import { Link } from 'react-router-dom';


const LandingPage = () => {
    return ( 
        <>
        <div className='flex justify-center items-center md:pt-6 pt-6'>
            <img src={rad_logo} className="md:h-auto h-auto" alt="logo" />
        </div>
        <div className='text-center mt-2 font-raleway'>
          <h6 className='text-primary text-2xl md:text-3xl font-bold'>REACH A DEAL GROUP</h6>
        </div>

        <div className='grid md:grid-cols-2 mt-8 md:mt-10 landing_height'>
            <div className='flex justify-center flex-col items-center landing-first'>
                <div>
                    <h5 className='text-center font-raleway text-white font-extrabold text-3xl md:text-4xl max-w-sm leading-10'>REACH A DEAL AFRICA LIMITED</h5>
                </div>
                <Link to="/africa" className='flex items-center mt-5 cursor-pointer'>
                    <p className='font-raleway text-white font-bold text-base'>Corporate Website</p>
                    <img className='ml-3' src={arrow_right} alt='arrow' />
                </Link>      
            </div>
            <div className='flex justify-center flex-col items-center landing-second'>
                <div>
                    <h5 className='text-center font-raleway text-white font-extrabold text-3xl md:text-4xl max-w-sm leading-10' >REACH A DEAL NORTH AMERICA</h5>
                </div>
                <Link to="/north-america" className='flex items-center mt-5 cursor-pointer'>
                    <p className='font-raleway text-white font-bold text-base'>Corporate Website</p>
                    <img className='ml-3' src={arrow_right} alt='arrow' />
                </Link>
            </div>
        </div>

        <div className='text-center mt-5 mb-5 md:mt-8 md:mb-8'>
            <p className='text-black font-raleway text-base'>&copy; 2022 Reachadeal Group</p>
        </div>
        </>
     );
}
 
export default LandingPage;
