import React from 'react';
import NavbarComp from '../../components/Navbar/navbar';
import img1 from '../../assets/images/businessBig.png'
import ContactComp from '../../components/ContactForm/contact';
import FooterComp from '../../components/Footer/footer';


const BusinessPage = () => {
    return ( 
        <>
            <NavbarComp/>
            <div className='font-raleway container mx-auto px-6 py-10 md:py-16 md:px-12'>
                <div className='border-b border-b-tertiary6'>
                    <h3 className='text-radblack font-semibold text-3xl mb-3'>Our Services</h3>
                </div>

                <div className='grid md:grid-cols-2 md:pt-16 pt-10 md:gap-6'>
                    <div>
                        <h4 className='text-black font-bold text-xl'>BUSINESS ACQUISITION AND MERGERS</h4>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        Reach A deal Africa offers professional service in facilitating the mergers and acquisition of businesses across industries ranging from Agriculture, Logistics, Fast Moving Consumer Goods and Electronics and so on. We are involved in  vertical, horizontal, product-extension, market extension and conglomeration types of mergers and acquisitions.
                        </p>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        Our team of qualified professionals is well equipped with skills relating to research, valuation, due diligence examination and negotiation skills that would offer you an excellent and valuable business acquisition or merger.
                        </p>
                        <p className='mt-4 font-PtSans text-black text-sm max-w-xl'>
                        Are you interested in acquiring existing businesses or merging with existing businesses with the aim of scaling up? Then, Reach A deal Africa is the best professional service firm you should consult.
                        </p>
                      

                    </div>
                    <div className='mt-10 md:mt-0'>
                        <div>
                            <img src={img1} alt="services" />
                        </div>
                    </div>

                </div>
            </div>
            <ContactComp />
         <FooterComp/>
        </>
     );
}
 
export default BusinessPage;