import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BlogDetailsPage from "./pages/Blogs/blogById";
import BlogsPage from "./pages/Blogs/blogs";
import HomePage from "./pages/HomePage/home";
import LandingPage from "./pages/LandingPage/landing";
import NorthAmericaHomePage from "./pages/NorthAmerica/home";
import BusinessPage from "./pages/ServicesPage/Business";
import CommodityPage from "./pages/ServicesPage/Commodity";
import ConsultancyPage from "./pages/ServicesPage/Consultancy";
import ImportExportPage from "./pages/ServicesPage/Import";
import ServicesPage from "./pages/ServicesPage/Services";
import TradingPage from "./pages/ServicesPage/Trading";

function App() {
  return (
    <Router>
    <Routes>
      <Route exact path="/" element={<LandingPage />} />
      <Route path="/africa" element={<HomePage />} />
      <Route path="/north-america" element={<NorthAmericaHomePage />} />
      <Route path="/blog" element={<BlogsPage />} />
      <Route path="/blog/:id" element={<BlogDetailsPage />} />
      <Route path="/services/contracts" element={<ServicesPage />} />
      <Route path="/services/consultancy" element={<ConsultancyPage />} />
      <Route path="/services/import-export" element={<ImportExportPage />} />
      <Route path="/services/commodity" element={<CommodityPage />} />
      <Route path="/services/trading" element={<TradingPage />} />
      <Route path="/services/business-acquisition" element={<BusinessPage />} />
    </Routes>
  </Router>
  );
}

export default App;
