import React, {useState} from 'react';
import rad_logo from '../../assets/icons/rad_logo.svg'
import call from '../../assets/icons/call_icon.svg'
import mail from '../../assets/icons/mail_icon.svg'
import mail_dark from '../../assets/icons/mail_dark.svg'
import whatsapp from '../../assets/icons/whatsapp_icon.svg'
import youtube from '../../assets/icons/youtube.svg'
import facebook from '../../assets/icons/facebook.svg'
import { Link } from 'react-router-dom';


const FooterComp = () => {

    const [email, setEmail] = useState("")

    const handleSubmit = (e) =>{
        e.preventDefault()
        console.log(email)
    }
    return (  
        <>
            <div className='bg-radblack'>
                <div className='container mx-auto'>
                    <div className='grid md:grid-cols-2 border-b border-b-footerBorder'>
                        <div className='border-r border-r-footerBorder py-10 md:py-12 px-6 md:px-10'>   
                            <img src={rad_logo} className="h-16" alt="logo" />
                            <div className='mt-3'>
                                <h5 className='text-3 text-sm max-w-lg font-PtSans'>
                                At Reach A Deal Africa Limited, we provide a trading service like no other. As an African-based independent trading company, we prioritize efficiency and expert service delivery to our wide variety of clients.
                                </h5>
                            </div>
                            <div className='flex mt-8'>
                                <div className='flex items-center'>
                                    <div className='w-10 h-10 flex items-center justify-center bg-footerCircle rounded-full'>
                                        <img src={call} alt="call" className='w-5 h-5' />
                                    </div>
                                    <div className='ml-3'>
                                        <p className='text-3 font-PtSans text-sm'>Have a question?</p>
                                        <p className='text-white text-sm font-PtSans font-bold'>310-437-2766</p>
                                    </div>
                                </div>
                                <div className='flex md:pl-20 pl-14 items-center'>
                                    <div className='w-10 h-10 flex items-center justify-center bg-footerCircle rounded-full'>
                                        <img src={mail} alt="call" className='w-5 h-5' />
                                    </div>
                                    <div className='ml-3'>
                                        <p className='text-3 font-PtSans text-sm'>Contact us at</p>
                                        <p className='text-white text-sm font-PtSans font-bold'>info@</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-8 md:py-10 md:px-10 px-6'>
                            <h5 className='font-raleway text-white font-base font-bold'>Newsletter</h5>
                            <p className='text-3 text-sm mt-3 font-PtSans max-w-xl leading-6'>
                            Be the first one to know  about discounts, offers and events weekly in your mailbox. Unsubscribe whenever you like with one click.
                            </p>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <div className='mt-8 max-w-xl relative'>
                                    <span className='absolute left-5 top-2/4 -translate-y-2/4'>
                                        <img src={mail_dark} alt="call" className='w-5 h-5' />
                                    </span>
                                    <input
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                     className="appearance-none border-none font-PtSans placeholder:text-3 rounded-3xl w-full text-sm bg-footerCircle py-4 pl-12 pr-32 text-3 focus:outline-none" id="name" type="email" placeholder="Enter you email" required/>
                                    <button className='absolute right-2 top-2/4 -translate-y-2/4 bg-btnRad hover:bg-primary text-white rounded-3xl px-8 py-3 text-sm'>Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div className='md:py-10 py-12 px-6 md:px-10'>
                       <ul className='flex'>
                           <li>
                               <Link to="/home" className='text-3 text-sm font-PtSans hover:text-primary'>About Us</Link>
                           </li>
                           <li>
                               <Link to="/blog" className='text-3 text-sm font-PtSans px-4 hover:text-primary'>Blog</Link>
                           </li>
                           <li>
                               <Link to="/home" className='text-3 text-sm font-PtSans  hover:text-primary'>FAQ</Link>
                           </li>
                       </ul>
                       <div className='flex mt-5 justify-between items-center'>
                            <ul className='flex'>
                                <li>
                                    <Link to="/">
                                    <div className='w-10 h-10 flex items-center justify-center bg-footerCircle rounded-full'>
                                        <img src={facebook} alt="facebook" className='w-5 h-5' />
                                    </div>
                                    </Link>
                                </li>
                                <li className='px-3'>
                                    <Link to="/">
                                            <div className='w-10 h-10 flex items-center justify-center bg-footerCircle rounded-full'>
                                            <img src={youtube} alt="youtube" className='w-5 h-5' />
                                            </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/">
                                        <div className='w-10 h-10 flex items-center justify-center bg-footerCircle rounded-full'>
                                        <img src={whatsapp} alt="whatsapp" className='w-5 h-5' />
                                        </div>
                                    </Link>
                                </li>
                            </ul>
                            <div>
                                <p className='text-3 text-sm font-PtSans'>&copy; 2022, All Rights Reserved</p>
                            </div>
                       </div>
                      
                    </div>
                </div>

            </div>
        </>
    );
}
 
export default FooterComp;